import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRestaurant } from "../../context/restaurant-context";
import { FullScreenSpinner } from "../../tabin/components/fullScreenSpinner";
import { restaurantPath } from "../main";
import { useRegister } from "../../context/register-context";
import { toast } from "../../tabin/components/toast";
import { Button } from "../../tabin/components/button";

import "./restaurantList.scss";
import { useListRestaurantsQuery } from "../../hooks/useGetRestaurantsQuery";
import { useCart } from "../../context/cart-context";
import { getCloudFrontDomainName } from "../../private/aws-custom";
import { CachedImage } from "../../tabin/components/cachedImage";

export const RestaurantList = () => {
    const history = useHistory();
    const { products } = useCart();

    // useEffect(() => {
    //         const cameFromCheckoutPage = sessionStorage.getItem("cameFromCheckoutPage");

    const data = [{ id: "4a1793e4-c7e8-45d9-8611-dc5e1d86892b", name: "Zana", available: true }];

    const onOrder = (restaurantId: string) => {
        history.push(restaurantPath + `/${restaurantId}`);
    };

    return (
        <>
            <div className="restaurant-list">
                <CachedImage
                    url={`${getCloudFrontDomainName()}/protected/ap-southeast-2:25450a52-7a40-45ea-a868-a9d98c6ff86a/2023-00-13_02:29:01.554-Zana Logo.jpg`}
                    className="restaurant-list-logo"
                    alt="logo"
                />
                <div className="h5 text-center mb-6">
                    At Zana we serve seriously delicious thai food made from quality ingredients. Click Order Here here to start!
                </div>
                {/* <div className="h2 mt-4 mb-6 text-center">Select a Blazin Burger branch</div> */}

                {data &&
                    data.map((restaurant, index) => (
                        <div key={restaurant.id}>
                            {index != 0 && <div className="separator-4"></div>}
                            <div className="restaurant-list-item">
                                <div>{restaurant.name}</div>
                                <Button
                                    onClick={() => {
                                        onOrder(restaurant.id);
                                    }}
                                >
                                    {restaurant.available ? "Order Here" : "Unavailable"}
                                </Button>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
