import { useRestaurant } from "../../context/restaurant-context";
import { IS3Object } from "../../graphql/customQueries";
import { getCloudFrontDomainName } from "../../private/aws-custom";
import { CachedImage } from "../../tabin/components/cachedImage";
import { isMobile } from "react-device-detect";
import { format, isToday } from "date-fns";

export const OrderPlaced = (props: { orderScheduledAt: string | null }) => {
    const { restaurant } = useRestaurant();

    const RestaurantLogo = (props: { image: IS3Object }) => {
        return (
            <CachedImage
                url={`${getCloudFrontDomainName()}/protected/${props.image.identityPoolId}/${props.image.key}`}
                className="mb-4"
                style={{ width: "150px" }}
                alt="restaurant-logo"
            />
        );
    };

    return (
        <div style={{ padding: isMobile ? "24px" : "64px", textAlign: "center" }}>
            {restaurant && restaurant.logo && <RestaurantLogo image={restaurant.logo} />}
            <div className="h3">Your order has been placed!</div>
            <div className="h3 mt-3">Your food will be ready for pickup from {restaurant && restaurant.name}.</div>
            {!props.orderScheduledAt ? (
                <div className="h2 mt-3">
                    Pickup Time:{" "}
                    {restaurant && restaurant.preparationTimeInMinutes ? `Approx ${restaurant.preparationTimeInMinutes} minutes` : "ASAP"}
                </div>
            ) : isToday(new Date(props.orderScheduledAt)) ? (
                <div className="h2 mt-3">Pickup Time: Today, {format(new Date(props.orderScheduledAt), "h:mm a")}</div>
            ) : (
                <div className="h2 mt-3">Pickup Time: {format(new Date(props.orderScheduledAt), "do MMM yyyy, h:mm a")}</div>
            )}

            <div className="mt-3">You may now close this page.</div>
        </div>
    );
};
